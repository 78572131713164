import * as React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import Layout from "../components/layout";

// markup
const NewsPage = (data) => {
  return <Layout>news</Layout>;
};

export const query = graphql`
  query NewsQuery {
    allSanitySpecial {
      nodes {
        description
        name
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`;

export default NewsPage;
